import { addItemToCartMutation } from 'service/mutations';
import { Cart, Scalars, CartItemOptions, VariantStockDataArgs } from 'service/types/generated';
import { apiMutate } from 'service/utils/mutation';
import ServiceConfigs from 'service/config';
import { RenderingRegistry } from 'common/lib/registries';
import { useUpdateCartValues } from './utils';

type UseAddItemToCartType = ({ cartId, isCartAuthorized }: { cartId: string; isCartAuthorized?: boolean }) => ({
  menuItemId,
  variantQuantity,
  variantId,
  options,
  notes,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  isCartAuthorized,
  scheduledTime,
}: {
  menuItemId: string;
  variantQuantity: number;
  variantId: Scalars['ID'];
  options?: Array<CartItemOptions>;
  cartId?: string;
  isCartAuthorized?: boolean;
  notes?: string;
  scheduledTime: VariantStockDataArgs['scheduledTime'];
}) => Promise<void | Partial<Cart> | { hasError: boolean }>;

const useAddItemToCart: UseAddItemToCartType = ({ isCartAuthorized, cartId }) => {
  const updateCartValues = useUpdateCartValues({ cartId, isCartAuthorized });
  const isManualOrder = RenderingRegistry.getIsManualOrder();

  return variables => {
    const { isCartAuthorized: isCartAuthorizedVar, ...mutationVariables } = variables;
    return apiMutate({
      mutation: addItemToCartMutation,
      variables: { cartId, ...mutationVariables, subdomain: ServiceConfigs.getSubDomain(), isManualOrder },
      requestHeaders: isCartAuthorized || isCartAuthorizedVar ? { ...ServiceConfigs.getAuthorizationHeader() } : {},
      handler: (res: any) => res?.addItemToCart?.cart,
    }).then(async cart => {
      if (cart) {
        await updateCartValues(cart);
      } else {
        await updateCartValues();
      }
      return { hasError: !cart };
    });
  };
};

export default useAddItemToCart;
