/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import logger from './logger';
import ServiceConfigs from 'service/config';
import { setCookie } from 'nookies';
import { getFullDomain, fetchServerTime } from 'common/utils';

export const logToCloudWatch = logEvents => {
  const logPayload = {
    message: logEvents.message,
    timestamp: new Date().toISOString(),
    level: 'INFO',
  };

  fetch('https://gfcdhpx5y4gkjl7bu4ccc732z40gsvnb.lambda-url.eu-west-1.on.aws/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(logPayload),
  })
    .then(response => {
      if (!response.ok) {
        return response.text().then(text => {
          logger.error({ error: 'Logging to Cloudwatch Error', extraData: text });
        });
      }
      return response.json();
    })
    .catch(err => logger.error({ error: 'Logging to Cloudwatch Error', extraData: err }));
};

const getFacebookSharedEvents = (timestamp: number) => {
  const generateFbp = () => Cookies.get('_fbp') || generateAndSetFbpCookie(timestamp);

  const generateFbc = () => {
    const subdomain = ServiceConfigs.getSubDomain();
    const existingFbc = Cookies.get('_fbc');
    const existingUserFbclid = Cookies.get(`user_fbclid_${subdomain}`);

    if (existingFbc) {
      return existingFbc;
    }

    if (!existingUserFbclid) {
      return null;
    }

    return generateAndSetFbcCookie(timestamp, existingUserFbclid);
  };

  return {
    eventId: uuidv4(),
    eventTime: Math.floor(timestamp / 1000), // in seconds
    eventSourceUrl: window?.location?.href,
    browserInfo: {
      fbp: generateFbp(),
      fbc: generateFbc(),
      client_user_agent: window?.navigator?.userAgent,
    },
  };
};

const setFacebookCookie = (name: string, value: string) => {
  const maxAge = 30 * 24 * 60 * 60;
  setCookie(null, name, value, { path: '/', maxAge, domain: getFullDomain() });
};

const generateAndSetFbpCookie = (timestamp: number) => {
  const randomId = Math.round(Math.random() * 10000000000000);
  const fbpValue = `fb.1.${timestamp}.${randomId}`;
  setFacebookCookie('_fbp', fbpValue);
  return fbpValue;
};

const generateAndSetFbcCookie = (timestamp: number, existingUserFbclid: string) => {
  const fbcValue = `fb.1.${timestamp}.${existingUserFbclid}`;
  setFacebookCookie('_fbc', fbcValue);
  return fbcValue;
};

// track facebook pixels
const trackFacebookPixels = (eventName, eventValues, facebookSharedEvents) => {
  window.fbq(
    'track',
    eventName,
    {
      ...eventValues,
    },
    { eventID: facebookSharedEvents.eventId },
  );
};

// track facebook capi with backend
const trackFacebookCapi = async (eventName, eventValues, facebookShared, userData, capiMutation) => {
  let transformedObject = {};

  if (eventValues.contents) {
    transformedObject = {
      ...eventValues,
      contents: eventValues.contents.map(({ id, ...rest }) => ({
        ...rest,
        product_id: id,
      })),
    };
  } else {
    transformedObject = eventValues;
  }

  let ipAddress = null;
  try {
    const ipResponse = await fetch('https://api.ipify.org/?format=json');
    const data = await ipResponse.json();
    ipAddress = data?.ip;
  } catch (error) {
    logger.error({ error: 'IpAddress CAPI Error, 🚀 ~', extraData: error });
  }

  const capiPayload = {
    event_id: facebookShared.eventId,
    event_time: facebookShared.eventTime,
    event_source_url: facebookShared.eventSourceUrl,
    event_name: eventName,
    browserInfo: {
      ...facebookShared.browserInfo,
      ...(ipAddress && { client_ip_address: ipAddress }),
    },
    ...userData,
    ...transformedObject,
  };

  capiMutation({ ...capiPayload });
  return capiPayload;
};

// init facebook tracking event
export const trackFacebookEvent = async (eventName, eventValues, userData, capiMutation, capiEnabled) => {
  const serverTimestamp = await fetchServerTime();
  const facebookSharedEvents = getFacebookSharedEvents(serverTimestamp);

  try {
    if (window && window.fbq) {
      trackFacebookPixels(eventName, eventValues, facebookSharedEvents);
    }
  } catch (error) {
    logger.error({ error: 'Facebook Pixel Error, 🚀 ~ trackFacebookPixels', extraData: error });
  }

  if (capiEnabled) {
    try {
      await trackFacebookCapi(eventName, eventValues, facebookSharedEvents, userData, capiMutation);
    } catch (error) {
      logger.error({ error: 'Facebook CAPI Error, 🚀 ~ trackFacebookCapi', extraData: error });
    }
  }
};
