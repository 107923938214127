import { gql } from 'graphql-request';

const cartSummaryQuery = gql`
  query ($cartId: String!, $subdomain: String!) {
    cart(cartId: $cartId, subdomain: $subdomain) {
      uuid
      voucherCode
      total
      subtotal
      cartItemsCount
      deliveryFee
      serviceFee
      discountedAmount
      minPreprationTime
      deliveryTime
      voucherAmount
    }
  }
`;

export default cartSummaryQuery;
