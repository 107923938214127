import { gql } from 'graphql-request';

const fragmentCart = gql`
  fragment returnedCart on Cart {
    uuid
    minPreprationTime
    deliveryTime
    areaId
    deliveryZone
    voucherCode
    total
    subtotal
    cartItemsCount
    tax
    taxPercentage
    taxInclusive
    deliveryFee
    serviceFee
    discountedAmount
    voucherAmount
    voucherCode
    subtotalAfterVoucher
    subtotalBeforeVoucher
    branchId
    deliveryType
    possibleCashbackAmount
    voucherDiscountedItems
    cartItems {
      id
      posSynced
      menuItem {
        uuid
        photoUrl
        price
        titleAr
        titleEn
        fulfillmentMode
        publishedBranchIds
      }
      options {
        optionPrice
        optionQuantity
        optionGroup {
          uuid
        }
        option {
          titleAr
          titleEn
          uuid
          price
        }
      }
      totalAdditionalCharge
      totalPrice
      unitPrice
      notes
      variantQuantity
      variant {
        id
        price
        titleAr
        titleEn
        photoUrl
        prepTime
        images {
          photoUrl
        }
        stockData(scheduledTime: $scheduledTime) {
          unavailable
          stockCount
          trackingType
        }
      }
    }
  }
`;

export default fragmentCart;
