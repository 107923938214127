import { Translate } from 'next-translate';
import { itemAvailabilityInfo } from '.';
import { PRODUCT_AVAILABILITY } from '../../../common/constants';
import { ProductStockData, Setting, Variant, VariantStockData } from '../../../service/types';

type checkIfAllVariantsAreAvailableType = ({
  productVariants,
  t,
  lang,
  shouldInvalidateProduct,
  productFulfillmentMode,
  orderMode,
  stockThresholdSettings,
  productStockData,
}: {
  productVariants: Variant[];
  t: Translate;
  lang: string;
  shouldInvalidateProduct: boolean;
  productFulfillmentMode: string;
  orderMode: string;
  stockThresholdSettings: Partial<Setting>;
  productStockData: ProductStockData | VariantStockData;
}) => {
  availabilityText: string;
  availabilityTagVariant: string;
  unavailabilityReason: string;
  trackingType?: string;
  stockCount?: number;
  isLowInStock?: boolean;
};

export const checkIfAllVariantsAreAvailable: checkIfAllVariantsAreAvailableType = ({
  productVariants,
  t,
  lang,
  shouldInvalidateProduct,
  productFulfillmentMode,
  orderMode,
  stockThresholdSettings,
  productStockData,
}) => {
  const AreAllVariantsNotAvailable = productVariants?.every(variant => {
    const { unavailabilityReason } = itemAvailabilityInfo({
      t,
      stockData: variant.stockData,
      shouldInvalidateProduct,
      lang,
      productFulfillmentMode,
      mode: orderMode,
      stockThresholdSettings,
    });
    return unavailabilityReason === PRODUCT_AVAILABILITY.VARIANT_UNAVAILABLE;
  });

  const { availabilityText, availabilityTagVariant, unavailabilityReason, trackingType, stockCount, isLowInStock } =
    itemAvailabilityInfo({
      t,
      stockData: productStockData,
      shouldInvalidateProduct,
      lang,
      productFulfillmentMode,
      mode: orderMode,
      stockThresholdSettings,
    });

  return AreAllVariantsNotAvailable
    ? {
        availabilityText: t('soldOut'),
        availabilityTagVariant: 'secondary',
        unavailabilityReason: PRODUCT_AVAILABILITY.VARIANT_UNAVAILABLE,
      }
    : {
        availabilityText,
        availabilityTagVariant,
        unavailabilityReason,
        trackingType,
        stockCount,
        isLowInStock,
      };
};
