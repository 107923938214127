import { createHash } from 'crypto';
import { useStoreAnalytics, useUserInfo } from 'service/hooks';
import { usePersistedCountryDetails } from 'common/hooks';

export interface UserDataReturnType {
  userData: Record<string, string>;
  userDataBackEnd: Record<string, string>;
  snapChatData: Record<string, string>;
  capiEnabled: boolean;
  tiktokData: Record<string, string>;
}

const useUserData = (): UserDataReturnType => {
  const [persistedCountryDetails] = usePersistedCountryDetails();
  const { data: userInfo } = useUserInfo();
  const { data: analyticsData } = useStoreAnalytics();
  const { analytics } = analyticsData || {};
  const { capiEnabled, facebookAddTrackId } = analytics || {};
  const isCapiEnabled = capiEnabled && !!facebookAddTrackId;

  const hashingInput = input => {
    if (input) {
      return createHash('sha256').update(input).digest('hex');
    }
    return input;
  };

  const handleUserData = () => {
    if (userInfo) {
      const fullName = userInfo.name.toLowerCase().split(' ');
      const phoneNumber = userInfo.phoneNumber.replace(/\D/g, '');
      const sharedArgs = {
        fn: fullName[0],
        ln: fullName[fullName.length - 1],
        ph: hashingInput(phoneNumber),
        country: persistedCountryDetails?.code.toLowerCase(),
        external_id: hashingInput(phoneNumber),
      };
      if (userInfo.email) {
        return {
          ...sharedArgs,
          em: hashingInput(userInfo.email.toLowerCase()),
        };
      }
      return sharedArgs;
    }
    return {};
  };

  const handleUserDataBackEnd = () => {
    const fullName = userInfo?.name?.toLowerCase().split(' ') || '';
    const phoneNumber = userInfo?.phoneNumber?.replace(/\D/g, '') || '';
    const sharedArgs = {
      first_name: hashingInput(fullName[0]),
      last_name: hashingInput(fullName[fullName.length - 1]),
      phone: hashingInput(phoneNumber),
      country_code: hashingInput(persistedCountryDetails?.code.toLowerCase()),
      external_id: hashingInput(phoneNumber),
    };
    if (userInfo?.email) {
      return {
        ...sharedArgs,
        email: hashingInput(userInfo?.email.toLowerCase()),
      };
    }
    return sharedArgs;
  };

  const handleSnapchatUserData = () => {
    if (!userInfo) {
      return {};
    }

    const fullName = userInfo?.name?.toLowerCase().split(' ');
    const sharedArgs = {
      firstname: fullName[0],
      lastname: fullName[fullName?.length - 1],
      geo_country: persistedCountryDetails?.code?.toLowerCase(),
      ...(userInfo?.phoneNumber && {
        user_hashed_phone_number: hashingInput(userInfo?.phoneNumber?.replace(/\D/g, '')),
      }),
      ...(userInfo?.email && {
        user_hashed_email: hashingInput(userInfo?.email?.toLowerCase()),
      }),
    };
    return sharedArgs;
  };

  const handleTiktokUserData = () => {
    if (!userInfo) {
      return {};
    }

    const fullName = userInfo?.name?.toLowerCase().split(' ');
    const sharedArgs = {
      firstname: fullName[0],
      lastname: fullName[fullName?.length - 1],
      geo_country: persistedCountryDetails?.code?.toLowerCase(),
      ...(userInfo?.phoneNumber && {
        phone_number: hashingInput(userInfo?.phoneNumber?.replace(/\D/g, '')),
      }),
      ...(userInfo?.email && {
        email: hashingInput(userInfo?.email?.toLowerCase().trim()),
      }),
    };
    return sharedArgs;
  };

  return {
    userData: handleUserData() || {},
    userDataBackEnd: handleUserDataBackEnd() || {},
    snapChatData: handleSnapchatUserData() || {},
    capiEnabled: isCapiEnabled,
    tiktokData: handleTiktokUserData() || {},
  };
};

export default useUserData;
